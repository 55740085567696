import create from 'zustand';

export type GlobalState = {
    pageLoaded: boolean;
    setPageLoaded: (value: boolean) => void;
};

export const useGlobalState = create<GlobalState>((set) => ({
    pageLoaded: false,
    setPageLoaded: (value) => set(() => ({ pageLoaded: value })),
}));
