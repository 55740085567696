import { StyledFreeShippingProgress, StyledProgress } from './styled';
import TruckIcon from '$icons/delivery.svg';
import { Text } from '~/shared/components';
import { useBasket } from '~/features/basket';
import { useTranslation } from '~/shared/utils';
import { memo } from 'react';

export type Props = { variant?: 'standard' | 'minimal' };

export const FreeShippingProgress = memo(({ variant = 'standard' }: Props) => {
    const { translate } = useTranslation();
    const { data: basketData } = useBasket();
    const {
        rawFractionToFreeShipping = 0,
        displayAmountBeforeFreeShipping,
        isOnlyGiftCardsInBasket,
        rawShippingPrice,
    } = basketData || {};

    const dontShowFreeShippingProgress =
        rawShippingPrice && displayAmountBeforeFreeShipping === undefined;

    const fontVariant = variant === 'minimal' ? 'caption' : 'bodySm';

    if (isOnlyGiftCardsInBasket || dontShowFreeShippingProgress) {
        return null;
    }

    return (
        <StyledFreeShippingProgress variant={variant}>
            {rawFractionToFreeShipping < 1 ? (
                <>
                    <Text variant={fontVariant}>
                        {translate('basket.neededForFreeShipping', {
                            value: displayAmountBeforeFreeShipping || '',
                        })}
                    </Text>
                    {variant !== 'minimal' && (
                        <StyledProgress
                            fraction={rawFractionToFreeShipping}
                            max={100}
                        ></StyledProgress>
                    )}
                </>
            ) : (
                <>
                    <TruckIcon aria-hidden="true" />
                    <Text variant={fontVariant}>{translate('basket.freeShippingReached')}</Text>
                </>
            )}
        </StyledFreeShippingProgress>
    );
});
