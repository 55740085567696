import { Text, TextShade } from '$shared/components';
import { useTheme } from '@emotion/react';
import React, { ComponentProps, PropsWithChildren, useCallback, useState } from 'react';
import { StyledToolTip, StyledToolTipContent, StyledToolTipContentInner } from './styled';

export type TooltipProps = {
    /**
     * Supply content for thumbnail
     */
    content?: string | JSX.Element;
    /**
     * Supply percentage distance from center, default is 80%
     */
    distance?: number;
    /**
     * This attribute is added to the target element on which the Tooltip gets opened, when focusing or hovering over it.
     * It usually holds the randomly generated Id value of the Tooltip element.
     */
    describedBy?: string;

    position?: 'top' | 'bottom';
} & Omit<ComponentProps<typeof StyledToolTip>, 'content'>;

/**
 * Basic tooltip, appears on hover has a fixed position which is bottom
 */
export const Tooltip = ({
    children,
    content,
    describedBy,
    distance,
    position = 'bottom',
    ...rest
}: PropsWithChildren<TooltipProps>) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const showTooltip = useCallback(() => setIsOpen(true), [setIsOpen]);
    const hideTooltip = useCallback(() => setIsOpen(false), [setIsOpen]);

    if (!content) {
        return <>{children}</>;
    }

    return (
        <StyledToolTip
            onFocus={showTooltip}
            onBlur={hideTooltip}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
            isOpen={isOpen}
            {...rest}
        >
            {children}
            <StyledToolTipContent
                role="tooltip"
                aria-describedby={describedBy}
                aria-hidden={!isOpen}
                distance={distance}
                position={position}
            >
                <StyledToolTipContentInner>
                    {typeof content === 'string' ? (
                        <Text
                            children={content}
                            variant="caption"
                            shade={theme.traits.tooltip.shade as TextShade}
                        />
                    ) : (
                        content
                    )}
                </StyledToolTipContentInner>
            </StyledToolTipContent>
        </StyledToolTip>
    );
};
