import { useMutation, useQueryClient } from 'react-query';
import { useBasketPaths } from './useBasketPaths';
import { BasketViewModel } from '~/lib/data-contract';
import { useNotification } from '~/shared/hooks/useNotification';
import { removeVoucher } from '../service';

export const useVoucherRemove = () => {
    const { basketUrl, basketQueryKey } = useBasketPaths();
    const queryClient = useQueryClient();
    const { push } = useNotification();
    const apiUrl = `${basketUrl}/voucher/remove`;

    const { isLoading, mutateAsync: remove } = useMutation<
        BasketViewModel,
        Response,
        { voucherId: string }
    >(({ voucherId }) => removeVoucher({ voucherId, apiUrl }), {
        onMutate: () => {
            // Optimisticly show update
        },
        onSuccess: (basket) => {
            queryClient.setQueryData(basketQueryKey, basket);

            if (basket.conversionErrors?.length) {
                basket.conversionErrors.forEach((error) => push({ severity: 'info', text: error }));
            }
        },
    });

    return {
        isLoading,
        remove,
    };
};
