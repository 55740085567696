import styled from '@emotion/styled';
import { Text } from '~/shared/components';
import { ifProp } from 'styled-tools';

export const StyledImageWrapper = styled.span(({ theme }) => ({
    display: 'inline',
    marginRight: theme.spaces['2'],
    height: 18,
    width: 18,
    flex: '0 0 18px',
}));

export const StyledUsp = styled.div({
    display: 'flex',
    alignItems: 'center',
});

export const StyledUspText = styled(Text)<{ isSmall?: boolean; isBold?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.fontSizes.xs,
        fontWeight: theme.fontWeights.bold,
        textTransform: 'uppercase',
        transform: 'translateY(1px)', // Better visual alignment with icon
        letterSpacing: '1px',
    }),
    ifProp('isSmall', ({ theme }) => ({
        fontSize: theme.fontSizes['xs'],
        fontWeight: theme.fontWeights.regular,
    })),
    ifProp('isBold', {
        fontWeight: 600,
    })
);

export const StyledLink = styled.a<{ textDecoration?: string }>(({ textDecoration = 'none' }) => ({
    textDecoration,
}));
