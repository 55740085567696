import { StyledBlock } from './styled';

export type Props = {
    height?: number;
    width?: number;
    shimmer?: boolean;
    cover?: boolean;
};

export const Skeleton = StyledBlock;
