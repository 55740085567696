import { useMutation, useQueryClient } from 'react-query';
import { useBasketState } from '~/features/basket';
import { BasketViewModel, AddToBasketArgs, LineItemViewModel } from '~/lib/data-contract';
import { useBasketPaths } from './useBasketPaths';
import { useNotification } from '~/shared/hooks/useNotification';
import { useFranchise, useGtm, gtmProductMapper, errorToJson } from '~/shared/utils';
import { addItem } from '../service';

type Config = {
    showMiniBasketOnAdd?: boolean;
};

export type AddArgs = AddToBasketArgs & {
    trackingEvent?: keyof Pick<
        ReturnType<typeof useGtm>,
        'trackAddToBasket' | 'trackMiniBasketAddToBasket'
    >;
};

export const useBasketAdd = ({ showMiniBasketOnAdd }: Config = { showMiniBasketOnAdd: true }) => {
    const { push } = useNotification();
    const { basketLineUrl, basketQueryKey } = useBasketPaths();
    const { toggleMiniBasket } = useBasketState();
    const { activeMarket } = useFranchise();
    const events = useGtm();
    const queryClient = useQueryClient();
    const basketAddUrl = `${basketLineUrl}/add`;

    const { isLoading, mutateAsync: add } = useMutation<BasketViewModel, Response, AddArgs>(
        (variant) => addItem(variant, basketAddUrl),
        {
            onMutate: () => {
                showMiniBasketOnAdd && toggleMiniBasket(true);
            },
            onSuccess: (basket, variant) => {
                if (basket.conversionErrors?.length) {
                    showMiniBasketOnAdd && toggleMiniBasket(false);
                    basket.conversionErrors.forEach((error) => {
                        push({ severity: 'warning', text: error });
                    });
                }
                queryClient.setQueryData(basketQueryKey, basket);

                // GTM tracking
                const trackingFunc = events?.[variant?.trackingEvent ?? 'trackAddToBasket'];
                const basketLine = basket.lines?.find(
                    (line: LineItemViewModel) => line.id === variant.variantId
                );
                if (basketLine && trackingFunc) {
                    trackingFunc(
                        gtmProductMapper([{ ...basketLine, quantity: variant.quantity }]),
                        activeMarket?.currency
                    );
                }
            },
            onError: async (error) => {
                showMiniBasketOnAdd && toggleMiniBasket(false);
                const { level, errorMessages } = await errorToJson(error);
                push({ severity: level, text: errorMessages });
            },
        }
    );

    return {
        isLoading,
        add,
    };
};
