import { AddGiftCardArgs, BasketViewModel } from '~/lib/data-contract';
import { postRequest } from '~/shared/utils';

export async function addGiftcard(args: AddGiftCardArgs, apiUrl: string) {
    const request = postRequest<BasketViewModel>(apiUrl, args);

    const response = await request();

    return response;
}
