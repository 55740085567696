import React, { PropsWithChildren } from 'react';
import { StyledDescriptionTableData, StyledSummaryTableData } from '../styled';

/**
 * Renders a tr width corresponding td's must be used inside a table
 * Only renders row if it has a value
 *
 */
export const SummaryTableRow = ({
    children,
    value,
    type,
}: PropsWithChildren<{ value?: JSX.Element | string; type?: 'discount' | 'earning' }>) => {
    const isJSX = (value?: JSX.Element | string): value is JSX.Element => {
        if (!value) {
            return false;
        }
        return (value as JSX.Element).props !== undefined;
    };

    // Don't render components without a value
    return (isJSX(value) && value.props.children) || value ? (
        <tr>
            <StyledDescriptionTableData>{children}</StyledDescriptionTableData>
            <StyledSummaryTableData type={type}>{value}</StyledSummaryTableData>
        </tr>
    ) : null;
};
