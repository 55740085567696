import { BasketViewModel } from '~/lib/data-contract';
import { deleteRequest } from '~/shared/utils';

export async function removeVoucher({ voucherId, apiUrl }: { apiUrl: string; voucherId: string }) {
    const request = deleteRequest<BasketViewModel>(apiUrl, {
        voucherId,
    });

    const response = await request();

    return response;
}
