import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Progress } from '../../../../shared/components/Progress/Progress';
import { Props } from './FreeShippingProgress';

export const StyledFreeShippingProgress = styled.div<Props>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        gap: theme.spaces[2],
        color: theme.colors.dark,
        svg: {
            width: theme.spaces[4],
            height: theme.spaces[4],
        },
    }),
    switchProp('variant', {
        standard: {
            flexDirection: 'column',
        },
        minimal: {
            justifyContent: 'center',
            svg: {
                width: '18px',
            },
        },
    })
);

export const StyledProgress = styled(Progress)(({ theme }) => ({
    alignSelf: 'stretch',
    marginTop: theme.spaces[3],
    padding: `0 ${theme.spaces[3]}`,
}));
