import { useMutation, useQueryClient } from 'react-query';
import { useBasketPaths } from './useBasketPaths';
import { AddGiftCardArgs, BasketViewModel, RemoveGiftCardArgs } from '~/lib/data-contract';
import { useNotification } from '~/shared/hooks/useNotification';
import { addGiftcard, removeGiftcard } from '../service';
export const useGiftcard = () => {
    const { addGiftcardUrl, removeGiftcardUrl, basketQueryKey } = useBasketPaths();
    const queryClient = useQueryClient();
    const { push } = useNotification();

    const { isLoading, mutateAsync: add } = useMutation<BasketViewModel, Response, AddGiftCardArgs>(
        (args) => addGiftcard(args, addGiftcardUrl),
        {
            onSuccess: (basket) => {
                queryClient.setQueryData(basketQueryKey, basket);

                if (basket.conversionErrors?.length) {
                    push({ severity: 'info', text: basket.conversionErrors });
                }
            },
        }
    );

    const { isLoading: isLoadingRemove, mutateAsync: remove } = useMutation<
        BasketViewModel,
        Response,
        RemoveGiftCardArgs
    >((args) => removeGiftcard(args, removeGiftcardUrl), {
        onSuccess: (basket) => {
            queryClient.setQueryData(basketQueryKey, basket);

            if (basket.conversionErrors?.length) {
                push({ severity: 'info', text: basket.conversionErrors });
            }
        },
    });

    return {
        isLoading,
        add,
        isLoadingRemove,
        remove,
    };
};
