import styled from '@emotion/styled';
import { Props } from './Skeleton';
import { cover as coverStyle, shimmer as shimmerStyle } from '~/shared/utils/styled';

export const StyledBlock = styled.div<Props>(({ theme, width, height, cover, shimmer }) => ({
    backgroundColor: theme.colors.light30,
    ...(width ? { width: `${width}px` } : {}),
    ...(height ? { height: `${height}px` } : {}),
    ...(cover ? { ...coverStyle } : {}),
    ...(shimmer ? { ...shimmerStyle } : {}),
}));
