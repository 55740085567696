import { AddToBasketArgs, BasketViewModel } from '~/lib/data-contract';
import { postRequest } from '~/shared/utils';

export async function addItem(addToBasketArgs: AddToBasketArgs, apiUrl: string) {
    const request = await postRequest<BasketViewModel>(apiUrl, addToBasketArgs);

    const response = await request();

    return response;
}
