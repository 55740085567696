import styled from '@emotion/styled';

export const StyledProgressWrapper = styled.div({});

export const StyledProgressCustomBar = styled.div<{ height: number }>(({ theme, height }) => ({
    backgroundColor: theme.colors.light30,
    position: 'relative',
    height,
}));

export const StyledProgressCustomValue = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.dark,
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    transitionProperty: 'transform',
    transitionDuration: theme.animations.getDuration('slow01'),
    transitionTimingFunction: theme.animations.getEasing('standard', 'expressive'),
}));
