import { BasketViewModel, RemoveGiftCardArgs } from '~/lib/data-contract';
import { deleteRequest } from '~/shared/utils';

export async function removeGiftcard(args: RemoveGiftCardArgs, apiUrl: string) {
    const request = deleteRequest<BasketViewModel>(apiUrl, args);

    const response = await request();

    return response;
}
