import React, { memo, useMemo } from 'react';
import { UspSimple } from '~/lib/data-contract';
import { Image, RouterLink, Tooltip, Text } from '~/shared/components';
import { TextProps, TextShade } from '../Text';
import { StyledImageWrapper, StyledLink, StyledUsp, StyledUspText } from './styled';
import parse from 'html-react-parser';

export type Props = {
    uspItem?: UspSimple;
    shade?: TextShade;
    textDecoration?: string;
    tooltipPosition?: React.ComponentProps<typeof Tooltip>['position'];
} & Pick<TextProps, 'variant'>;

export const Usp = memo(
    ({ uspItem, shade, textDecoration, variant = 'body', tooltipPosition = 'bottom' }: Props) => {
        const { link, text, image, description } = uspItem ?? {};

        const parsedDescription = useMemo(() => {
            if (description) {
                return parse(description);
            }

            return null;
        }, [description]);

        if (!uspItem) {
            return null;
        }

        const child = (
            <Tooltip
                content={
                    parsedDescription ? (
                        <Text as="div" variant="caption" shade="paragraphInverted">
                            {parsedDescription}
                        </Text>
                    ) : undefined
                }
                style={{ whiteSpace: 'nowrap' }}
                distance={155}
                describedBy="usp-tooltip"
                position={tooltipPosition}
                disableOnSm
            >
                <StyledUsp tabIndex={link?.url ? undefined : 0}>
                    {image?.src && (
                        <StyledImageWrapper>
                            <Image
                                src={image.src}
                                alt={image.alt ?? ''}
                                sizes="24px"
                                mimeType="image/svg+xml"
                                skeletonShade="none"
                                showSpinner={false}
                                invert={
                                    shade === 'paragraphInverted' || shade === 'headlineInverted'
                                }
                            />
                        </StyledImageWrapper>
                    )}
                    <StyledUspText
                        as="span"
                        variant={variant}
                        shade={shade}
                        style={{ color: shade ? undefined : 'currentColor' }}
                    >
                        {link?.text || text}
                    </StyledUspText>
                </StyledUsp>
            </Tooltip>
        );

        return link?.url ? (
            <RouterLink href={link.url} prefetch={false}>
                <StyledLink
                    aria-label={link.title}
                    target={link.target}
                    textDecoration={textDecoration}
                >
                    {child}
                </StyledLink>
            </RouterLink>
        ) : (
            child
        );
    }
);
