import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Text } from '~/shared/components/Text';
import { center } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';

export const StyledSummary = styled(Text.withComponent('table'))(({ theme }) => ({
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 0.5em',
    [breakpoints.md]: {
        '&:first-of-type': {
            paddingLeft: theme.spaces[4],
        },
        '&:last-of-type': {
            paddingRight: theme.spaces[4],
        },
    },
}));

export const StyledSummaryText = styled(Text)({
    color: 'inherit',
});

export const StyledSummaryDivider = styled.div(({ theme }) => ({
    height: 1,
    backgroundColor: theme.traits.line.color,
    margin: `${theme.spaces[2]} auto`,
    [breakpoints.md]: {
        margin: `${theme.spaces[2]} -${theme.spaces[4]}`,
    },
}));

export const StyledVoucherContainer = styled.div<{ discount?: boolean }>({
    ...center,
    justifyContent: 'start',
});

export const StyledDescriptionTableData = styled.td<{ discount?: boolean }>(({ theme }) => ({
    verticalAlign: 'middle',
    color: theme.colors.dark50,
}));

export const StyledSummaryTableData = styled.td<{ type?: 'discount' | 'earning' }>(
    ({ theme }) => ({
        textAlign: 'right',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        color: theme.colors.dark50,
    }),
    switchProp('type', {
        ['discount']: ({ theme }) => ({
            color: theme.colors.red,
        }),
        ['earning']: ({ theme }) => ({
            color: theme.colors.green90,
        }),
    })
);
