import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { getDuration, getEasing } from '~/theme/themes/animations/baseAnimations';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

export const StyledToolTipContent = styled.span<{ distance?: number; position?: 'top' | 'bottom' }>(
    {
        display: 'none',
        position: 'absolute',
        justifyContent: 'center',
        left: 0,
        right: 0,
        textAlign: 'center',
        pointerEvents: 'none',
        animationName: slideUpAndFade,
        animationDuration: getDuration('fast02'),
        animationTimingFunction: getEasing('standard', 'productive'),
        animationFillMode: 'forwards',
    },
    switchProp('position', {
        top: ({ distance }) => ({
            bottom: `${distance}%`,
        }),
        bottom: ({ distance }) => ({
            top: `${distance}%`,
        }),
    })
);

export const StyledToolTip = styled.div<{ isOpen?: boolean; disableOnSm?: boolean }>(
    {
        position: 'relative',
    },
    ifProp('isOpen', {
        zIndex: 1, // Position on top of siblings when open
        [`${StyledToolTipContent}`]: {
            display: 'flex',
        },
    }),
    ifProp('disableOnSm', () => ({
        pointerEvents: 'none',
        a: {
            pointerEvents: 'auto',
        },
        [breakpoints.md]: {
            pointerEvents: 'auto',
        },
    }))
);

export const StyledToolTipContentInner = styled.span(({ theme }) => ({
    display: 'block',
    backgroundColor: theme.traits.tooltip.backgroundColor,
    borderRadius: 2,
    padding: `${theme.spaces[1]} ${theme.spaces[2]}`,
    border: theme.traits.tooltip.border,
}));
